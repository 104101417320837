define('ember-cli-adapter-pattern/utils/required-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = requiredProperty;


  /*
   * Utility method, returning a computed
   * property that throws an error unless
   * defined. This is like implementing
   * abstract methods.
   *
   * @method requiredProperty
   *
   * @param {String} propertyName
   *   Name of the required property.
   *
   * @return {Function}
   *   An 'abstract' method implementation.
   */
  function requiredProperty(propertyName) {
    (true && !(propertyName) && Ember.assert('Property name is required for requiredProperty.', propertyName));


    return Ember.computed(function () {
      throw new Ember.Error('Definition of property ' + propertyName + ' is required.');
    });
  }
});