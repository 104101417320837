define('ember-cli-analytics/integrations/google-analytics', ['exports', 'ember-cli-analytics/integrations/base', 'ember-cli-analytics/utils/can-use-dom', 'ember-cli-analytics/utils/without'], function (exports, _base, _canUseDom, _without) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var sendEvent = { hitType: 'pageview' };
      var event = Ember.merge(sendEvent, options);

      if (_canUseDom.default) {
        window.ga('send', event);
      }
    },


    /*
     * Send an arbitrary event to the
     * analytics engine.
     *
     * @method trackEvent
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var sendEvent = { hitType: 'event' };
      var gaEvent = {};

      if (options.nonInteraction) {
        gaEvent.nonInteraction = options.nonInteraction;
        delete options.nonInteraction;
      }

      for (var key in options) {
        var value = options[key];

        // If key is not a 'dimension' or 'metric', prepend with 'event'
        var shouldPrefix = !/^(dimension|metric)[0-9]{1,2}/.test(key);
        if (shouldPrefix) {
          key = 'event' + Ember.String.capitalize(key);
        }

        gaEvent[key] = value;
      }

      var event = Ember.merge(sendEvent, gaEvent);

      if (_canUseDom.default) {
        window.ga('send', event);
      }
    },


    /*
     * Identify an anonymous user with a
     * unique ID. This is useful when a
     * user returns to the application
     * an we wish to further track them.
     *
     * This should not be called in
     * conjunction with alias.
     *
     * @method identity
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var id = options.id;
      (true && !(id) && Ember.assert('You must pass a distinct id', id));


      if (_canUseDom.default) {
        window.ga('set', 'userId', id);
      }
    },


    /*
     * Insert the JavaScript tag into the
     * page, and perform any necessary
     * setup.
     *
     * @method insertTag
     * @on init
     */
    insertTag: Ember.on('init', function () {
      var config = Ember.copy(Ember.get(this, 'config'));
      var id = config.id,
          remarketing = config.remarketing,
          ecommerce = config.ecommerce,
          enhancedEcommerce = config.enhancedEcommerce,
          set = config.set;

      var properties = (0, _without.default)(config, 'id', 'remarketing', 'ecommerce', 'enhancedEcommerce', 'set');

      (true && !(id) && Ember.assert('You must pass a valid `id` to the GoogleAnaltics adapter', id));


      if (!_canUseDom.default) {
        return;
      }

      if (!window.ga) {
        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
          }, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        /* eslint-enable */
      }

      if (Ember.isPresent(Object.keys(properties))) {
        window.ga('create', id, properties);
      } else {
        window.ga('create', id, 'auto');
      }

      if (remarketing) {
        window.ga('require', 'displayfeatures');
      }

      if (ecommerce) {
        window.ga('require', 'ecommerce');
      }

      if (enhancedEcommerce) {
        window.ga('require', 'ecommerce');
      }

      if (set) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.keys(set)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var attr = _step.value;

            window.ga('set', attr, set[attr]);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    }),

    /*
     * Remove the JavaScript tag from the
     * page, and perform any necessary
     * teardown.
     *
     * @method removeTag
     * @on willDestroy
     */
    removeTag: Ember.on('willDestroy', function () {
      if (_canUseDom.default) {
        Ember.$('script[src*="google-analytics"]').remove();
        delete window.ga;
      }
    })
  });
});