define('ember-cli-adapter-pattern/utils/required-method', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = requiredMethod;


  /*
   * Utility method, returning a function that
   * throws an error unless defined. This is
   * like implementing abstract methods.
   *
   * @method requiredMethod
   *
   * @param {String} methodName
   *   Name of the required method.
   *
   * @return {Function}
   *   An 'abstract' method implementation.
   */
  function requiredMethod(methodName) {
    (true && !(methodName) && Ember.assert('Method name is required for requiredMethod.', methodName));


    return function () {
      throw new Ember.Error('Definition of method ' + methodName + ' is required.');
    };
  }
});