define("ember-cli-analytics/utils/without", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = without;
  /*
   * Return an object with each of the
   * listed keys removed.
   *
   * @method without
   *
   * @param {Object} original
   *   The original object.
   *
   * @param {Rest} args
   *   A list of keys to remove from the original object.
   */
  function without(original) {
    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    var originalKeys = Object.keys(original);
    var newObject = {};

    originalKeys.forEach(function (key) {
      if (args.indexOf(key) === -1) {
        newObject[key] = original[key];
      }
    });

    return newObject;
  }
});