define('ember-cli-analytics/mixins/trackable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    /*
     * Inject the analytics service
     * so we may make use of multiple
     * analytics integrations.
     */
    analytics: Ember.inject.service(),

    /*
     * Push the page transition to all
     * analytics adapters.
     *
     * @method trackPageView
     * @on didTransition
     */
    trackPageView: Ember.on('didTransition', function () {
      var analytics = Ember.get(this, 'analytics');
      var owner = Ember.getOwner(this);

      (true && !(analytics) && Ember.assert('Could not find the analytics service.', analytics));


      var limitRouteInformation = false;
      if (owner) {
        var config = owner.resolveRegistration('config:environment');
        limitRouteInformation = Ember.get(config, 'analytics.options.limitRouteInformation');
      }

      var routeData = limitRouteInformation ? Ember.get(this, 'currentRouteName') : Ember.get(this, 'url');
      analytics.trackPage({ page: routeData, title: routeData });
    })
  });
});