define('ember-cli-analytics/integrations/bing', ['exports', 'ember-cli-analytics/integrations/base', 'ember-cli-analytics/utils/can-use-dom'], function (exports, _base, _canUseDom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     */
    trackPage: function trackPage() {
      if (_canUseDom.default) {
        window.uetq.push('pageLoad');
      }
    },


    /*
     * Send a conversion completion
     * event to the analytics engine.
     *
     * @method trackConversion
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackConversion: function trackConversion() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var value = options.value;

      var sendEvent = { ec: 'purchase', gv: value };

      (true && !(value) && Ember.assert('You must pass a value', value));


      if (_canUseDom.default) {
        window.uetq.push(sendEvent);
      }
    },


    /*
     * Insert the JavaScript tag into the
     * page, and perform any necessary
     * setup.
     *
     * @method insertTag
     * @on init
     */
    insertTag: Ember.on('init', function () {
      var config = Ember.copy(Ember.get(this, 'config'));
      var id = config.id;
      (true && !(id) && Ember.assert('You must pass a valid `id` to the Bing adapter', id));


      if (_canUseDom.default && !window.uetq) {
        /* eslint-disable */
        (function (w, d, t, r, u) {
          var f, n, i;w[u] = w[u] || [], f = function f() {
            var o = { ti: id };o.q = w[u], w[u] = new UET(o), w[u].push('pageLoad');
          }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
            var s = this.readyState;s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null);
          }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i);
        })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
        /* eslint-enable */
      }
    }),

    /*
     * Remove the JavaScript tag from the
     * page, and perform any necessary
     * teardown.
     *
     * @method removeTag
     * @on willDestroy
     */
    removeTag: Ember.on('willDestroy', function () {
      if (_canUseDom.default) {
        Ember.$('script[src*="bing"]').remove();
        delete window.uetq;
      }
    })
  });
});