define('ember-cli-analytics/integrations/google-adwords', ['exports', 'ember-cli-analytics/integrations/base', 'ember-cli-analytics/utils/can-use-dom', 'ember-cli-analytics/utils/without'], function (exports, _base, _canUseDom, _without) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    /*
     * Send a conversion completion
     * event to the analytics engine.
     *
     * @method trackConversion
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackConversion: function trackConversion() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var config = Ember.copy(Ember.get(this, 'config'));
      var id = config.id,
          label = config.label;
      var value = options.value;

      var properties = (0, _without.default)(options, 'value');

      (true && !(id) && Ember.assert('You must pass a valid `id` to the GoogleAdwords adapter', id));
      (true && !(label) && Ember.assert('You must pass a valid `label` to the GoogleAdwords adapter', label));


      var googleAdwordsEvent = Ember.merge({
        'google_conversion_id': id,
        'google_conversion_language': 'en',
        'google_conversion_format': '3',
        'google_conversion_color': 'ffffff',
        'google_conversion_label': label,
        'google_remarketing_only': false
      }, properties);

      if (Ember.isPresent(value)) {
        googleAdwordsEvent['google_conversion_value'] = value;
      }

      if (_canUseDom.default) {
        window['google_trackConversion'](googleAdwordsEvent);
      }
    },


    /*
     * Insert the JavaScript tag into the
     * page, and perform any necessary
     * setup.
     *
     * @method insertTag
     * @on init
     */
    insertTag: Ember.on('init', function () {
      if (_canUseDom.default && !window['google_trackConversion']) {
        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i[r] = i[r] || function () {
            (i['r'].q = i['r'].q || []).push(arguments);
          };
          a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 0;a.src = g;m.parentNode.insertBefore(a, m);
        })(window, document, 'script', '//www.googleadservices.com/pagead/conversion_async.js', 'google_trackConversion');
        /* eslint-enable */
      }
    }),

    /*
     * Remove the JavaScript tag from the
     * page, and perform any necessary
     * teardown.
     *
     * @method removeTag
     * @on willDestroy
     */
    removeTag: Ember.on('willDestroy', function () {
      if (_canUseDom.default) {
        Ember.$('script[src*="googleadservices"]').remove();
        delete window.google_trackConversion;
      }
    })
  });
});