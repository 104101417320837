define('@fortawesome/ember-fontawesome/components/fa-icon', ['exports', '@fortawesome/ember-fontawesome/templates/components/fa-icon', '@fortawesome/fontawesome-svg-core', 'ember-get-config'], function (exports, _faIcon, _fontawesomeSvgCore, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function getConfigOption(key, defaultValue) {
    return Ember.getWithDefault(_emberGetConfig.default, 'fontawesome.' + key, defaultValue);
  }

  function objectWithKey(key, value) {
    return Array.isArray(value) && value.length > 0 || !Array.isArray(value) && value ? _defineProperty({}, key, value) : {};
  }

  function classList(previousClasses) {
    var _classes;

    var classes = (_classes = {
      'fa-spin': this.get('spin'),
      'fa-pulse': this.get('pulse'),
      'fa-fw': this.get('fixedWidth'),
      'fa-border': this.get('border'),
      'fa-li': this.get('listItem'),
      'fa-flip-horizontal': this.get('flip') === 'horizontal' || this.get('flip') === 'both',
      'fa-flip-vertical': this.get('flip') === 'vertical' || this.get('flip') === 'both'
    }, _defineProperty(_classes, 'fa-' + this.get('size'), this.getWithDefault('size', null) !== null), _defineProperty(_classes, 'fa-rotate-' + this.get('rotation'), this.getWithDefault('rotation', null) !== null), _defineProperty(_classes, 'fa-pull-' + this.get('pull'), this.getWithDefault('pull', null) !== null), _classes);

    return Object.keys(classes).map(function (key) {
      return classes[key] ? key : null;
    }).filter(function (key) {
      return key;
    }).concat(previousClasses.filter(function (c) {
      return !c.match(/^fa-/);
    }));
  }

  function normalizeIconArgs(prefix, icon) {
    var defaultPrefix = getConfigOption('defaultPrefix', 'fas');

    if (!icon) {
      return { prefix: defaultPrefix, iconName: null };
    }

    if ((typeof icon === 'undefined' ? 'undefined' : _typeof(icon)) === 'object' && icon.prefix && icon.iconName) {
      return icon;
    }

    if (typeof prefix === 'string' && typeof icon === 'string') {
      return { prefix: prefix, iconName: icon };
    }

    if (typeof icon === 'string') {
      return { prefix: defaultPrefix, iconName: icon };
    }
  }

  var IconComponent = Ember.Component.extend({
    layout: _faIcon.default,
    tagName: 'svg',
    classNameBindings: ['class'],
    attributeBindings: [
    // attributes watched for mutation
    'data-prefix', 'data-icon', 'data-fa-transform', 'data-fa-mask', 'data-fa-processed',
    // accessibility attributes
    'aria-hidden', 'aria-labelledby',
    // svg attributes
    'role', 'xmlns', 'viewBox', 'safeStyle:style'],
    html: Ember.computed('children', function () {
      var children = this.get('children');
      var newHtml = void 0;
      if (!children) {
        newHtml = Ember.String.htmlSafe('');
      } else {
        newHtml = Ember.String.htmlSafe(children.reduce(function (acc, cur) {
          return '' + acc + (0, _fontawesomeSvgCore.toHtml)(cur);
        }, ''));
      }
      return newHtml;
    }),
    safeStyle: Ember.computed('_frameworkStyle', function () {
      var frameworkStyle = this.get('_frameworkStyle');
      return frameworkStyle ? Ember.String.htmlSafe('' + this.get('_frameworkStyle')) : undefined;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      /* eslint ember/no-attrs-in-components: 0 */
      if ('_frameworkStyle' in this.attrs) throw new Error('_frameworkStyle attribute is reserved for internal use and may not be set from a template');
      var iconLookup = normalizeIconArgs(this.get('prefix'), this.get('icon'));
      var classes = objectWithKey('classes', [].concat(_toConsumableArray(classList.bind(this)(this.getWithDefault('class', '').split(' ')))));
      var transformProp = this.get('transform');
      var transform = objectWithKey('transform', typeof transformProp === 'string' ? _fontawesomeSvgCore.parse.transform(transformProp) : transformProp);
      var mask = objectWithKey('mask', normalizeIconArgs(null, this.get('mask')));
      var symbol = this.getWithDefault('symbol', false);

      var o = Object.assign({}, classes, transform, mask, { symbol: symbol });

      var renderedIcon = (0, _fontawesomeSvgCore.icon)(iconLookup, o);

      if (renderedIcon) {
        var abstract = renderedIcon.abstract[0];
        this.set('children', abstract.children);
        abstract.attributes && Object.keys(abstract.attributes).forEach(function (attr) {
          if (attr === 'style') {
            _this.set('_frameworkStyle', abstract.attributes[attr]);
          } else {
            _this.set(attr, abstract.attributes[attr]);
          }
        });
      } else {
        Ember.Logger.warn('Could not find icon: iconName=' + iconLookup.iconName + ', prefix=' + iconLookup.prefix);
        this.set('class', _fontawesomeSvgCore.config.replacementClass);
        this.set('viewBox', '0 0 448 512');
      }
    }
  });

  // Enables {{fa-icon 'iconnamehere'}} syntax, while still allowing {{fa-icon icon='iconnamehere'}}
  IconComponent.reopenClass({
    positionalParams: ['icon']
  });

  exports.default = IconComponent;
});